import React, { FC, useEffect } from 'react'
import useForm from '@hooks/useForm'
import {
  FormUser,
  initialValues,
  initialValidation,
  TFormUser
} from '@components/Form/FormUser'
import GenericFormPage from '@templates/Generic/Form'
import { Button } from 'gatsby-material-ui-components'
import { ApiResponse, getUser, updateUser, UserResponse } from '@utils/api'
import { Grid } from '@mui/material'
import useApi from '@hooks/useApi'
import { PageProps } from 'gatsby'
import { Card, KeyValueTable } from '@components'

const SettingsUsersAddPage: FC<PageProps> = ({ params }) => {
  const api = useApi<UserResponse>({
    apiMethod: getUser,
    params: params.username
  })

  const form = useForm<TFormUser>({
    initialValues,
    initialValidationRules: initialValidation
  })

  const updateUserApi = useApi<ApiResponse<string>>({
    apiMethod: updateUser,
    requestOnMount: false
  })

  useEffect(() => {
    if (api.response) {
      const { email, username, full_name, roles } = api.response
      form.setValues({
        email,
        username,
        full_name,
        roles
      })
    }
  }, [api.response])

  return (
    <GenericFormPage
      title={params.username}
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Users Management',
          to: '/settings/users/'
        },
        {
          title: 'Add Edit User'
        }
      ]}
      form={form as any}
      api={updateUserApi}
      returnPath="/settings/users/"
      asideContent={
        <Card heading="User Info" style={{ height: 'auto' }}>
          <KeyValueTable
            variant="label-over"
            loading={api.loading}
            dataPoints={[
              ['Created', api.response?.created_date],
              ['Last Login', api.response?.last_login_date || '- no data -'],
              ['Last IP', api.response?.last_login_ip || '- no data -'],
              ['Failed Logins', api.response?.logins_failed_count]
            ]}
          />
        </Card>
      }
      formActions={
        <Grid container spacing={2}>
          <Grid item>
            <Button
              data-cy="submitUpdatedUser"
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              disabled={api.loading}
            >
              Update User
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="large"
              variant="text"
              onClick={() => window !== undefined && window.history.back()}
            >
              {'back'}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <FormUser
        form={form as any}
        includePassword={false}
        disableUsername={true}
      />
    </GenericFormPage>
  )
}

export default SettingsUsersAddPage
